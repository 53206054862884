/* eslint-disable no-undef */
require("@rails/ujs").start()
require("jquery")

require("packs/notification_button")

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))
/* eslint-enable no-undef */

import { Controller } from "stimulus"

// NOTE: We are currently not using Stimulus 3 so we don't have param functionality. However, we've named these similar in the view to mimic how they might be implemented in the future.
export default class extends Controller {
    static targets = ["startAt", "endAt"]

    setToNow(event) {
        var utc = this.getUtcParam(event)
        var target = eval(`this.${event.srcElement.dataset.datetimeTargetParam}Target`)
        target.value = this.daysAgo(0, utc).toISOString().substring(0, 16)
        return false
    }

    /**
     *
     * @param event
     * @returns false
     */
    // setDateTimeDaysAgo({ params: { days } }) {
    setDaysAgo(event) {
        // NOTE: We are currently not using Stimulus 3 so we don't have param functionality. However, we've named these similar in the view to mimic how they might be implemented in the future.
        var days = event.srcElement.dataset.datetimeDaysParam
        var utc = this.getUtcParam(event)
        var target = eval(`this.${event.srcElement.dataset.datetimeTargetParam}Target`)
        target.value = this.daysAgo(days, utc).toISOString().substring(0,16)
        return false
    }

    // PRIVATE

    /**
     * Gets the value of the UTC data parameter.
     *
     * @param event
     * @returns {boolean}
     */
    getUtcParam(event) {
        return event.srcElement.dataset.datetimeUtcParam === 'true'
    }

    /**
     *
     * @param days {Integer} Days to subtract from now.
     * @param utc {Boolean} Whether to use UTC time instead of browser local timezone.
     * @returns Date
     */
    daysAgo(days, utc) {
        var d = new Date()
        d.setDate(d.getDate() - days)
        if (!utc) {
            // shift time since zone offset is ignored when populating field value
            d = new Date(d - d.getTimezoneOffset() * 60000)
        }
        return d
    }
}

import js_cookie from "js-cookie"

// Allow closing/deleting notification.
// See https://bulma.io/documentation/elements/notification/#javascript-example
document.addEventListener("DOMContentLoaded", () => {
    (document.querySelectorAll(".notification .delete") || []).forEach($delete => {
        let notification = $delete.parentNode
        let notificationName = notification.attr("name")

        if(js_cookie.get(notificationName)) {
            notification.remove()
        } else {
            $delete.addEventListener("click", () => {
                js_cookie.set(notificationName, "deleted")
                notification.remove()
            })
        }
    })
})

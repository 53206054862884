import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["label"]

    toggle() {
        /* eslint-disable no-undef */
        var checkboxes = $('input[type="checkbox"]')
        /* eslint-enable no-undef*/
        checkboxes.prop("checked", !checkboxes.prop("checked"))
    }
}

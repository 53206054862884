import { Controller } from "stimulus"
import MicroModal from "micromodal"

export default class extends Controller {
    static targets = [ "startAt", "endAt", "resendBox", "submitButton", "modalContent" ]

    // modalIndex keeps track of what modal "step" you're on.
    // used to decide what content should be displayed in modal
    initialize() {
        this.modalIndex = 0
    }

    // reset modalIndex so first step is always shown on modal open
    openModal() {
        if (this.dateRangeIsValid(this.startAtTarget.value, this.endAtTarget.value)) {
            // show a warning modal if they checked 'force records?' / "resend" in the UI
            if (this.resendBoxTarget.checked) {
                this.modalIndex = 0
                this.showCurrentModalContent()
                MicroModal.show('resend-modal')
            } else {
                this.performResend()
            }
        }
    }

    // show next modal content
    nextModalContent() {
        this.modalIndex++
        this.showCurrentModalContent()
    }

    // hide/display modal content based on modalIndex
    showCurrentModalContent() {
        this.modalContentTargets.forEach((element, index) => {
            element.hidden = index != this.modalIndex
        })
    }

    // submit resend form by clicking hidden submit button
    performResend() {
        this.submitButtonTarget.click()
    }

    // return false unless user's selected date range is valid
    dateRangeIsValid(startAt, endAt) {
        startAt = Date.parse(startAt)
        endAt = Date.parse(endAt)
        if (isNaN(startAt) || isNaN(endAt)) {
            alert("Please choose both a start date and an end date.")
            return false
        }
        if (startAt > endAt) {
            alert("You must choose a start date that is before your selected end date.")
            return false
        }
        return true
    }
}
